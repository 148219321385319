/**
 * pageCache
 * @author wwl
 * @created 2024/3/19
 * @modified 2024/3/19
 */
// import LRU from 'lru-cache';
import { ServerMiddleware } from '@nuxt/types';
// import { handleSetCookie } from '~/util/api';
const LRU = require('lru-cache');

const cache = new LRU({
  max: 100, // 缓存队列长度 最大缓存数量
  maxAge: 1000 * 1800, // 缓存时间600秒 单位：毫秒 3600秒=1小时
});

const cacheMiddleware: ServerMiddleware = function (req, res, next) {
  // console.log('pageCache中间件', process.env.NODE_ENV, req.originalUrl);
  // console.log('18-18', req); // req.headers.referer req.headers.host
  // if (res.statusCode === 404) {
  //   // res.redirect(301,`/404`);
  //   res.writeHead(301, { Location: '/404' });
  //   res.end();
  // }

  // res.setHeader(
  //   'Set-Cookie',
  //   `sourceReferer=${req.headers.referer}; Path=/; HttpOnly`
  // ); // 设置cookie sourceReferer
  let cookieObj: any = {
    maxAge: 60 * 60 * 24 * 1,
    path: '/',
    // domain: 'jufair.com',
  };
  let domainStr = '';
  let domainStr2 = '';
  if (process.env.NODE_ENV !== 'development') {
    // 非开发环境时 设置一级域名cookie
    cookieObj['domain'] = 'jufair.com';
    domainStr = 'jufair.com';
    domainStr2 = 'domain=jufair.com;';
  }
  let seoList = ['baidu.com', 'google.com', 'so.com', 'sougou'];
  let checkSeo = () => {
    let checkSeoRes = false;
    for (let i = 0; i < seoList.length; i++) {
      if (req.headers.referer?.includes(seoList[i])) {
        checkSeoRes = true;
        break;
      }
    }
    return checkSeoRes;
  };
  if (req.headers.hasOwnProperty('referer') && checkSeo()) {
    // $cookies.set('sourceReferer', req.headers.referer, cookieObj);
    res.setHeader(
      'Set-Cookie',
      `sourceReferer=${req.headers.referer}; ${
        domainStr ? domainStr : ''
      } Path=/; HttpOnly`
    ); // 设置cookie sourceReferer
    // req.store.commit('global/sourceReferer', req.headers.referer);
  }
  if (process.env.NODE_ENV !== 'development') {
    try {
      const pathname = <string>req.originalUrl;
      // console.log('24-24', pathname);
      // 清理缓存
      if (pathname === '/cleancache') {
        cache.reset();
        res.statusCode = 200;
      }
      // 只有首页才进行缓存 if (['/'].includes(pathname) || pathname.startsWith('/information'))  pathname.startsWith('/exhibition')
      // 只有百科首页进行缓存
      if (pathname == '/wiki') {
        const existsHtml = cache.get(pathname);
        if (existsHtml) {
          console.warn(
            `${new Date().toJSON()} 命中缓存 >>>`,
            pathname,
            existsHtml.html?.length
          );
          //  如果没有Content-Type:text/html 的 header，gtmetrix网站无法做测评
          res.setHeader('Content-Type', ' text/html; charset=utf-8');
          return res.end(existsHtml.html, 'utf-8');
        } else {
          (res as any).original_end = res.end;
          res.end = function (data: any) {
            console.warn(
              `${new Date().toJSON()} 写入缓存 >>>`,
              pathname,
              res.statusCode,
              data?.length
            );
            if (res.statusCode === 200) {
              // 设置缓存
              cache.set(pathname, {
                html: data,
              });
            }
            (res as any).original_end(data, 'utf-8');
          };
        }
      }
    } catch (error) {
      console.log(`page-cache-middleware: ${error}`);
      next();
    }
  }
  next();
};

export default cacheMiddleware;
